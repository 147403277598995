.settings-dialog-wrapper .modal-dialog-container .modal-dialog {
	width: 1000px;
}

#tabSettingsContent .tab-pane {
	height: calc(100vh - 270px);
}

#tabSettingsContent .tab-pane.prompts {
	overflow: auto;
}

#tabSettingsContent .table-wrapper {
	height: calc(100vh - 300px);
	overflow: auto;
}

#tabReportSettingsContent .tab-pane {
	height: calc(100vh - 347px);
}

#tabReportSettingsContent .tab-pane.prompts {
	overflow: auto;
}

#tabReportSettingsContent .table-wrapper {
	height: calc(100vh - 380px);
	overflow: auto;
}

#tabPowerCommandsContent .tab-pane {
	height: calc(100vh - 424px);
}

#tabPowerCommandsContent .tab-pane.prompts {
	overflow: auto;
}

#tabPowerCommandsContent .table-wrapper {
	height: calc(100vh - 458px);
	overflow: auto;
}

.settings-dialog-wrapper .new-item-wrapper {
	position: absolute;
	top: -44px;
	right: 0px;
	z-index: 2;
}

table tr th.category,
table tr td.category {
	max-width: 230px;
	width: 230px;
}

table tr th.citations,
table tr td.citations,
table tr th.content,
table tr td.content {
	width: 280px;
	max-width: 280px;
}

.placeholder-item .btn {
	margin-top: -3px;
	margin-bottom: 2px;
}
